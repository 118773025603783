import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		redirect: '/queryUrl',
		component: () => import('@/views/login/index'),

	},
	{
		path: '/queryUrl',
		name: 'queryUrl',
		component: () => import('@/views/login/index'),
	},
  {
		path: '/failed',
		name: 'failed',
		component: () => import('@/views/login/failed'),
	},
  {
		path: '/Case',
		name: 'Case',
		component: () => import('@/views/Case'),
	},
]

const router = new VueRouter({
	routes
})

export default router
