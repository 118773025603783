<template>
  <div id="app">
    <!-- <nav></nav> -->
    <keep-alive exclude="repaymentDetails,extend">
      <router-view></router-view>
    </keep-alive>
    <!-- <router-view v-if="!$route.meta.keepAlive"></router-view> -->
  </div>
</template>

<script>
// import { getCustomerServiceConfig } from "@/api/index";
export default {
  data() {
    return {};
  },
  async mounted() {

        // localStorage.setItem("BaseInfo", JSON.stringify(bsInfo));


  },
  methods: {
    async getCustomerService() {
      let querys = {
        ...this.$store.state.flutter_baseInfo,
      };
      const res = await getCustomerServiceConfig(querys);
      localStorage.setItem("helpCenterUrl", res.data.helpCenterUrl);
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
